import api from '../../../app/api/api'
import handleError from '../../../app/api/handleError'
import { AppThunk } from '../../../app/store'
import { message } from 'antd'

export const sendSlackMessage =
  (slackMessage: string): AppThunk =>
  async () => {
    try {
      await api.post('/v4/slack-notification', {
        message: slackMessage,
      })
    } catch (error: any) {
      message.error(handleError(error))
    }
  }
