import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { Alert, Form, Tooltip, message } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'

import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthenticationToken } from '../../app/auth/authentication'
import envConfig from '../../app/config'
import * as assetsSlice from '../../features/assets/assetsSlice'
import styles from './MultipleIconsUpload.module.scss'
import { Icon } from '../../features/assets/types'
import { sendSlackMessage } from '../../features/util/slack/slack'

interface MultipleIconsUploadProps {
  onUploadComplete(iconsFromUpload: Icon): void
  onUploadFailed?(errorMessage: string): void
}

const MultipleIconsUpload: FunctionComponent<MultipleIconsUploadProps> = ({
  onUploadComplete,
  onUploadFailed,
}) => {
  const dispatch = useDispatch()
  const family = useSelector(assetsSlice.selectFamily)
  const category = useSelector(assetsSlice.selectCategory)
  const subcategory = useSelector(assetsSlice.selectSubcategory)
  const [uploading, setUploading] = useState(false)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [totalFilesHandled, setTotalFilesHandled] = useState(0)
  const [newIcons, setNewIcons] = useState<string[]>([])
  const [uploadToken, setUploadToken] = useState<string>('')

  const handleUploadComplete = (info: UploadChangeParam) => {
    try {
      setUploading(false)
      setFileList(info.fileList)
      onUploadComplete(info.file.response)
      dispatch(assetsSlice.actions.addIconOnList(info.file.response))

      const newIconsUpdated = [...newIcons, info.file.response.hash]
      const totalFilesHandledUpdated = totalFilesHandled + 1
      setNewIcons(newIconsUpdated)
      setTotalFilesHandled(totalFilesHandledUpdated)
      handleAutoTagging(totalFilesHandledUpdated, newIconsUpdated)

      if (totalFilesHandledUpdated === fileList.length) {
        dispatch(
          sendSlackMessage(
            'Multiple icons upload finished. Please make sure to publish the changes if no more uploads are needed. c.c <@aumeung>'
          )
        )
      }
    } catch (error) {
      message.error('Error processing the updloaded icon.')
      setUploading(false)
    }
  }

  const normalizeFileListFormat = (fileUploadEvent: UploadChangeParam): UploadFile[] =>
    fileUploadEvent.fileList

  const handleUploadFailed = (info: UploadChangeParam) => {
    const totalFilesHandledUpdated = totalFilesHandled + 1
    setTotalFilesHandled(totalFilesHandledUpdated)
    handleAutoTagging(totalFilesHandledUpdated, newIcons)

    setUploading(false)
    if (onUploadFailed)
      onUploadFailed(`${info.file.name} Failed: ${info.file.response.messages.join(', ')}`)
  }

  const handleAutoTagging = (filesHandled: number, iconsHashes: string[]) => {
    if (family && subcategory && filesHandled === fileList.length) {
      dispatch(assetsSlice.generateTagsForIcons(iconsHashes, family.productType))
      setNewIcons([])
    }
  }

  if (family && category && subcategory) {
    let content
    if (uploading) {
      content = (
        <div className={styles.uploadingIcon}>
          <LoadingOutlined />
          <p>Uploading icons</p>
        </div>
      )
    } else {
      content = (
        <>
          <p className="ant-upload-drag-icon">
            <UploadOutlined width={18} />
          </p>
          <p className={styles.draggerText}>Click or drag file to this area to upload</p>
          <p className={styles.draggerTextSmall}>File type accepted : .svg</p>
        </>
      )
    }

    const getUploadUrl = async () => {
      const token = await getAuthenticationToken()

      if (token) {
        setUploadToken(token)
      }

      return `${envConfig.API_URL}/v4/icons/${family?.slug}/${category?.slug}/${subcategory?.slug}/upload?size=${family?.defaultDownloadSize}&subcategoryHash=${subcategory?.hash}`
    }

    return (
      <div className={styles.draggerArea}>
        <Tooltip title="Click or drag file to this area to upload new icons">
          <Form>
            <Form.Item
              valuePropName="fileList"
              getValueFromEvent={normalizeFileListFormat}
              name="fileList"
            >
              <Dragger
                name="icon"
                multiple
                action={getUploadUrl}
                headers={{ Authorization: uploadToken }}
                onChange={(info) => {
                  if (info.file.status === 'uploading') {
                    setUploading(true)
                  } else if (info.file.status === 'done') {
                    handleUploadComplete(info)
                  } else if (info.file.status === 'error') {
                    handleUploadFailed(info)
                  }
                }}
                accept=".svg"
                listType="picture"
                fileList={fileList}
                itemRender={(_originNode, file) => (
                  <div className={file.error ? styles.error : ''}>
                    <img src={file.thumbUrl} alt={file.name} width="104px" height="104px" />
                    {file.status === 'uploading' && (
                      <>
                        Uploading...
                        <LoadingOutlined />
                      </>
                    )}
                    {file.error && (
                      <Alert
                        message={`${file.name} upload failed!`}
                        description={file.response.messages.join(', ')}
                        type="error"
                      />
                    )}
                  </div>
                )}
              >
                {content}
              </Dragger>
            </Form.Item>
          </Form>
        </Tooltip>
      </div>
    )
  }
  throw new Error('Family, category and subcategory must be in state')
}

export default MultipleIconsUpload
